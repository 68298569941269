import { FC, ComponentPropsWithoutRef } from 'react';

import { MenuItem } from '@material-ui/core';
import Link from 'next/link';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof MenuItem>, 'button'> & {
  /**
   * Адрес страницы.
   */
  href?: string;
};

/**
 * Отображает пункт меню пользователя в шапке сайта.
 */
const HeaderProfileMenuItem: FC<Props> = ({ href = '', ...props }) => (
  <Link href={href} passHref>
    <MenuItem
      {...props}
      // @ts-ignore
      component="a"
    />
  </Link>
);

export default HeaderProfileMenuItem;
