import React, { FC, ComponentPropsWithoutRef } from 'react';

import { Toolbar, Box, makeStyles, Typography } from '@material-ui/core';
import cn from 'classnames';

import BrandIcon from '../logo/BrandIcon';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Box>;

/**
 * Возвращает классы компонента.
 */
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderTopColor: theme.palette.divider,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    minHeight: 81,
    zIndex: theme.zIndex.drawer + 1,
  },

  logo: {
    color: theme.palette.primary.main,

    height: 40,
    width: 154,
  },

  text: {
    marginLeft: 'auto',
  },

  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 'bold',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

/**
 * Подвал приложения
 */
const Footer: FC<Props> = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <Toolbar
      {...props}
      className={cn(classes.root, className)}
      component="footer"
    >
      <BrandIcon className={classes.logo} />
      <Typography className={classes.text} variant="body2">
        Если у вас возникли сложности,{' '}
        <a
          href="https://docs.google.com/spreadsheets/d/18wfm8hjIDwqUzrMhW4S06vAQtgJ1s3HokXrngAKLMQg/edit#gid=0"
          rel="noreferrer"
          target="_blank"
          className={classes.link}
        >
          <strong>обратитесь в поддержку</strong>
        </a>{' '}
        и мы поможем вам.
      </Typography>
    </Toolbar>
  );
};

export default Footer;
