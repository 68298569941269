import { FC, ComponentPropsWithoutRef } from 'react';

import { Box, makeStyles, Theme } from '@material-ui/core';
import cn from 'classnames';

/**
 * Свойства стилей.
 */
type StyleProps = {
  /**
   * Указывает, что слева от контента есть дополнительная область.
   */
  hasAside?: boolean;
};

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Box> & StyleProps;

/**
 * Коллекция классов компонента.
 */
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {},

  main: {
    flex: 1,

    paddingBottom: 100,

    paddingLeft(p) {
      return p.hasAside ? 32 + theme.custom.asideContent.width : 48;
    },

    paddingRight: 48,
    paddingTop: 32,
  },
}));

/**
 * Оборачивает основое содержимое страницы.
 */
const Content: FC<Props> = ({ className, children, hasAside, ...props }) => {
  const classes = useStyles({
    hasAside,
  });

  return (
    <Box {...props} component="main" className={cn(classes.root, className)}>
      <Box className={classes.main}>{children}</Box>
    </Box>
  );
};

export default Content;
