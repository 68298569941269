import { FC, ComponentPropsWithoutRef, ReactNode } from 'react';

import { MenuItem, Box, makeStyles } from '@material-ui/core';
import cn from 'classnames';
import color from 'color';
import Link from 'next/link';
import { useRouter } from 'next/router';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof MenuItem>, 'button'> & {
  /**
   * Адрес страницы.
   */
  href?: string;

  /**
   * компонент баджа, в который можно прокидывать
   * числовые индикаторы, например
   */
  badge?: ReactNode;
};

/**
 * Возвращает классы компонента.
 */
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',

    fontSize: theme.typography.body2.fontSize,
    color: color(theme.palette.primary.contrastText).fade(0.5).string(),

    '&:hover': {
      backgroundColor: color(theme.palette.primary.dark).fade(0.75).string(),
    },
  },

  active: {
    color: theme.palette.primary.contrastText,
  },
}));

/**
 * Отображает пункт основного меню в шапке сайта.
 */
const HeaderMenuItem: FC<Props> = ({
  className,
  children,
  href = '',
  badge,
  ...props
}) => {
  const { asPath, isReady } = useRouter();
  const classes = useStyles();

  const active = isReady && href.length && asPath.startsWith(href);

  return (
    <Link href={href} passHref>
      <MenuItem
        {...props}
        className={cn(classes.root, active && classes.active, className)}
        // @ts-ignore
        component="a"
      >
        <Box component="span">{children}</Box>
        {badge}
      </MenuItem>
    </Link>
  );
};

export default HeaderMenuItem;
