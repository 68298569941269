/**
 * Статус выполнения фоновой задачи.
 */
export enum TaskStatus {
  /**
   * Задача завершена.
   */
  Finished = 'finished',

  /**
   * Задача в процессе.
   */
  Pending = 'in_progress',
}

/**
 * Статус выполнения подзадачи.
 */
export enum SubtaskStatus {
  /**
   * Подзадача успешно выполнена.
   */
  Finished = 'success',

  /**
   * Подзадача в процессе выполнения.
   */
  Pending = 'in_progress',

  /**
   * Подзадача завершилась с ошибкой.
   */
  Failed = 'failed',
}

/**
 * Результат выполнения задачи.
 */
export interface TaskResult {
  /**
   * Адрес результата.
   */
  url?: string;

  /**
   * Список договоров.
   */
  contracts?: {
    /**
     * Идентификатор договора.
     */
    id: string;

    /**
     * Статус выполнения подзадачи по договору.
     */
    status: SubtaskStatus;

    /**
     * Идентификатор ответственного, на которого назначили договоры
     * при выполнении задачи {@link TaskType.ContractsAssigneeChange}
     */
    assigneeId?: string;
  }[];
}

/**
 * Тип задачи.
 */
export enum TaskType {
  /**
   * Смена статуса договоров.
   */
  ContractsStatusChange = 'loans_change_status',

  /**
   * Назначение ответственного.
   */
  ContractsAssigneeChange = 'appointment_of_responsibilities',

  /**
   * Проверка банкротства.
   */
  BankruptcyCheck = 'checking_bankruptcy',

  /**
   * Проверка подсудности.
   */
  JurisdictionCheck = 'jurisdiction_check',

  /**
   * Расчет задолженности.
   */
  DebtCalculation = 'loan_debt_calculation',

  /**
   * Формирование шаблонов.
   */
  DocumentsGeneration = 'generate_templates',

  /**
   * Оплата ГП.
   */
  StateDutyPayment = 'state_fee_payment_requests',

  /**
   * Подтверждение оплаты ГП.
   */
  StateDutyPaymentApprove = 'state_fee_payment_approve',

  /**
   * Формирование отчета 1С УЗ.
   */
  RegisterReportGeneration = 'build_report_1c_uz',

  /**
   * Формирование файла для печати для одного договора.
   */
  PrintFileGeneration = 'build_loan_print_file',

  /**
   * Формирование архива с файлами для печати.
   */
  PrintArchiveGeneration = 'build_loans_print_archive',

  /**
   * Формирование реестра для банка.
   */
  StateDutyRegistryGeneration = 'state_fee_payments_registry_create',
}

/**
 * Данные задачи.
 */
export interface Task {
  /**
   * Уникальный идентификатор задачи.
   */
  id: string;

  /**
   * Статус выполнения задачи.
   */
  status: TaskStatus;

  /**
   * Тип задачи.
   */
  type: TaskType;

  /**
   * Дата запуска задачи.
   */
  dateStarted: Date;

  /**
   * Дата завершения задачи.
   */
  dateFinished?: Date;

  /**
   * Всего обработано подзадач.
   */
  processedTotal: number;

  /**
   * Количество удачно обработанных задач.
   */
  processedSuccess: number;

  /**
   * Количество обработанных задач с ошибкой.
   */
  processedError: number;

  /**
   * Результат выполнения задачи.
   */
  result?: TaskResult;
}
