/**
 * Ключ кеширования данных договора.
 */
export const CONTRACT_KEY = 'contract';

/**
 * Ключ кеширования данных договора.
 */
export const CONTRACT_BUREAU_ADDRESS_KEY = `${CONTRACT_KEY}_bureauAddress`;

/**
 * Ключ кеширования данных истории договора.
 */
export const CONTRACT_HISTORY_KEY = `${CONTRACT_KEY}_history`;

/**
 * Ключ кеширования данных подсудности договора.
 */
export const JURISDICTION_KEY = 'jurisdiction';

/**
 * Ключ кеширования данных последних определений подсудности договора.
 */
export const JURISDICTION_LAST_KEY = `${JURISDICTION_KEY}_last`;

/**
 * Ключ кеширования данных настроек подсудности договора.
 */
export const JURISDICTION_SETTINGS_KEY = `${JURISDICTION_KEY}_settings`;

/**
 * Ключ кеширования данных банкротсва по договору.
 */
export const BANKRUPTCY_KEY = 'bankruptcy';

/**
 * Ключ кеширования данных проверки банкротсва по договору.
 */
export const BANKRUPTCY_INSPECTION_KEY = `${BANKRUPTCY_KEY}_inspection`;

/**
 * Ключ кеширования данных проверки банкротсва по договору.
 */
export const DEBT_KEY = 'debt';

/**
 * Ключ кеширования должника по договору.
 */
export const DEBTOR_KEY = 'debtor';

/**
 * Ключ кеширования данных файлов по договору.
 */
export const ATTACHMENTS_KEY = 'attachments';

/**
 * Ключ кеширования данных гос. пошлины.
 */
export const STATE_DUTY_KEY = 'stateDuty';

/**
 * Ключ кеширования данных заявок гос. пошлин.
 */
export const STATE_DUTIES_KEY = 'stateDuties';

/**
 * Ключ кеширования данных печати.
 */
export const PRINT_KEY = 'print';

/**
 * Ключ кеширования данных платежей договора.
 */
export const PAYMENTS_KEY = 'payments';

/**
 * Ключ кеширования данных списка договоров.
 */
export const CONTRACTS_KEY = `${CONTRACT_KEY}[]`;

/**
 * Ключ кеширования данных истории.
 */
export const HISTORY_KEY = 'history';

/**
 * Ключ кеширования данных задач.
 */
export const TASKS_KEY = 'tasks';

/**
 * Ключ кеширования данных задач сотрудника.
 */
export const TASKS_EMPLOYEE_KEY = 'tasks_employee';

/**
 * Ключ кеширования списка СУ.
 */
export const COURTS_KEY = 'courts';
