import { FC, ComponentPropsWithoutRef, MouseEvent } from 'react';

import { MenuList } from '@material-ui/core';

import HeaderProfileMenuItem from './HeaderProfileMenuItem';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof MenuList>, 'onClick'> & {
  /**
   * Обрабатывает нажатие на элемент меню.
   */
  onClick?: (event: MouseEvent<HTMLElement>) => void;
};

/**
 * Отображает меню пользователя в шапке сайта.
 */
const HeaderProfileMenu: FC<Props> = ({ onClick, ...props }) => (
  <MenuList
    {...props}
    // @ts-ignore
    component="nav"
  >
    <HeaderProfileMenuItem onClick={onClick} href="/profile" disabled>
      Профиль
    </HeaderProfileMenuItem>
  </MenuList>
);

export default HeaderProfileMenu;
