import { useContext } from 'react';

import TasksContext from 'components/contexts/TasksContext';

/**
 * Возвращает значение контейнера задач.
 */
export default function useTasks() {
  return useContext(TasksContext);
}
