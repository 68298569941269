import { FC } from 'react';

import { Box, makeStyles } from '@material-ui/core';

import Link from 'components/ui/Link';
import Route from 'constants/Route';

/**
 * Возвращает коллекцию классов элементов.
 */
const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1.5)}px`,
    color: theme.palette.text.secondary,
  },
}));

/**
 * Отображает пустой блок оповещений в шапке.
 */
const HeaderNotificationEmpty: FC = () => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      Нет договоров, находящихся в работе. Добавьте действия в очередь в{' '}
      <Link href={Route.CONTRACTS_PORTFOLIO}>Портфеле</Link> и статус их
      выполнения отобразится здесь.
    </Box>
  );
};

export default HeaderNotificationEmpty;
