import { FC, ComponentPropsWithoutRef, useState } from 'react';

import {
  LinearProgress,
  AppBar,
  Toolbar,
  makeStyles,
  Fade,
  Theme,
  Box,
} from '@material-ui/core';
import cn from 'classnames';

import Link from 'components/ui/Link';
import Route from 'constants/Route';
import { ToolbarRoot } from 'components/portals/ToolbarPortal';

import BrandIcon from '../logo/BrandIcon';
import HeaderMenu from './HeaderMenu';
import HeaderNotification from './HeaderNotification';
import HeaderProfile from './HeaderProfile';
import HeaderSearch from './HeaderSearch';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof AppBar> & {
  /**
   * Указывает, что следует показать на шапке индикатор загрузки.
   */
  loading?: boolean;
};

/**
 * Свойства стилей.
 */
type StyleProps = {
  /**
   * Поле поиска активно.
   */
  searchActive?: boolean;
};

/**
 * Возвращает классы компонента.
 */
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,

    zIndex: theme.zIndex.drawer + 1,
  },

  primaryToolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },

  logo: {
    position: 'relative',
    top: -1,

    height: 40,
    width: 153,

    color: theme.palette.primary.contrastText,
  },

  menu: {
    alignSelf: 'stretch',

    marginLeft: 36,
  },

  search: {
    marginLeft: 'auto',

    transition: theme.transitions.create('width'),

    width(props) {
      return props.searchActive ? 700 : 300;
    },
  },

  icons: {
    marginLeft: theme.spacing(2),
  },

  progress: {
    position: 'absolute',
    bottom: -4,
    right: 0,
    left: 0,
  },
}));

/**
 * Отображает шапку сайта.
 */
const Header: FC<Props> = ({ className, loading, ...props }) => {
  const [searchActive, setSearchActive] = useState<boolean>(false);
  const classes = useStyles({ searchActive });

  return (
    <AppBar
      {...props}
      className={cn(classes.root, className)}
      position="sticky"
      component="header"
    >
      <Toolbar className={classes.primaryToolbar}>
        <Link href={Route.CONTRACTS_PORTFOLIO} className={classes.logo}>
          <BrandIcon />
        </Link>
        <Fade in={!searchActive} mountOnEnter unmountOnExit>
          <HeaderMenu className={classes.menu} />
        </Fade>
        <Box className={classes.search}>
          <HeaderSearch
            className={classes.search}
            active={searchActive}
            onActiveChange={setSearchActive}
          />
        </Box>
        <HeaderNotification className={classes.icons} />
        <HeaderProfile className={classes.icons} />
      </Toolbar>
      <ToolbarRoot />
      <Fade in={loading}>
        <LinearProgress color="secondary" className={classes.progress} />
      </Fade>
    </AppBar>
  );
};

export default Header;
