import { TaskType, TaskStatus } from 'types/entities/Task';

/**
 * Предоставляет методы для работы с фоновыми задачами.
 */
export default class TaskHelper {
  /**
   * Возвращает текст статуса для фоновой задачи.
   */
  public static getTaskStatusText(value?: TaskStatus) {
    switch (value) {
      case TaskStatus.Pending:
        return 'В процессе';

      case TaskStatus.Finished:
        return 'Завершена';

      default:
        return '—';
    }
  }

  /**
   * Возвращает текст типа для фоновой задачи.
   */
  public static getTaskTypeText(value?: TaskType) {
    switch (value) {
      case TaskType.DebtCalculation:
        return 'Расчет задолженности';

      case TaskType.JurisdictionCheck:
        return 'Проверка подсудности';

      case TaskType.BankruptcyCheck:
        return 'Проверка банкротства';

      case TaskType.DocumentsGeneration:
        return 'Формирование шаблонов';

      case TaskType.ContractsAssigneeChange:
        return 'Назначение ответственного';

      case TaskType.ContractsStatusChange:
        return 'Смена статуса договора';

      case TaskType.StateDutyPayment:
        return 'Оплата ГП';

      case TaskType.RegisterReportGeneration:
        return 'Формирование отчета 1С УЗ';

      case TaskType.PrintFileGeneration:
        return 'Формирование файла для печати';

      case TaskType.PrintArchiveGeneration:
        return 'Формирование архива с файлами для печати';

      case TaskType.StateDutyPaymentApprove:
        return 'Подтверждение оплаты ГП';

      case TaskType.StateDutyRegistryGeneration:
        return 'Формирование реестра для банка';
      default:
        return '';
    }
  }
}
