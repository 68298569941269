import { FC } from 'react';
import { Toolbar, Collapse } from '@material-ui/core';

import Portal from './Portal';

/**
 * Идентификатор элемента для монтирования панели инструментов.
 */
const CONTAINER_ID = 'toolbar';

/**
 * Отображает элемент для монтирования панели инструментов.
 */
export const ToolbarRoot: FC = () => <div id={CONTAINER_ID} />;

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Определяет, является ли панель инструментов активной.
   */
  active?: boolean;
};

/**
 * Отображает панель инструментов.
 */
const ToolbarPortal: FC<Props> = ({ active, children }) => (
  <Portal containerID={CONTAINER_ID}>
    <Collapse in={active}>
      <Toolbar>{children}</Toolbar>
    </Collapse>
  </Portal>
);

export default ToolbarPortal;
