import { FC, ComponentPropsWithoutRef } from 'react';

import { useRouter } from 'next/router';

import ContractsHelper from 'helpers/ContractsHelper';

import HeaderSearchForm from './HeaderSearchForm';

type Props = Omit<
  ComponentPropsWithoutRef<typeof HeaderSearchForm>,
  'searchType' | 'searchText'
>;

/**
 * Отображает строку поиска, в шапке сайта.
 */
const HeaderSearch: FC<Props> = ({ ...props }) => {
  const router = useRouter();

  if (!router.isReady) {
    return null;
  }

  const href = router.asPath;

  const text = ContractsHelper.getSearchTextFromHref(href);
  const type = ContractsHelper.getSearchTypeFromHref(href);

  return <HeaderSearchForm searchType={type} searchText={text} {...props} />;
};

export default HeaderSearch;
