import useSession from 'hooks/useSession';
import {
  ATTACHMENTS_KEY,
  BANKRUPTCY_KEY,
  CONTRACTS_KEY,
  CONTRACT_KEY,
  DEBT_KEY,
  JURISDICTION_KEY,
  JURISDICTION_LAST_KEY,
  PRINT_KEY,
  STATE_DUTIES_KEY,
  STATE_DUTY_KEY,
  TASKS_EMPLOYEE_KEY,
  TASKS_KEY,
} from 'queries/keys';
import { createContext, FC, useMemo, useState } from 'react';
import { useQueries, useQuery, useQueryClient } from 'react-query';
import { Task, TaskStatus, TaskType } from 'types/entities/Task';

/**
 * Интервал опроса статуса задачи.
 * @TODO: Вынести в конфиг(нет).
 */
const POLLING_INTERVAL = 3000;

/**
 * Значение контекста задач.
 */
type TasksContextValue = Readonly<{
  /**
   * Коллекция отслеживаемых задач.
   */
  readonly tasks: Record<string, Task>;

  /**
   * Записывает идентификатор задачи.
   * @param taskID Идентификатор задачи.
   */
  track(taskID: string, taskType: TaskType): void;

  /**
   * Признак того, что задача в процессе добавления на сервере.
   */
  isInitializing: boolean;

  /**
   * Устанавливает признак начала запуска задачи на сервере.
   */
  startInitialization(): void;

  /**
   * Устанавливает признак завершения запуска задачи на сервере.
   */
  finishInitialization(): void;
}>;

/**
 * Контекст контейнера задач.
 */
const TasksContext = createContext<TasksContextValue>(
  undefined! as TasksContextValue,
);

/**
 * Возвращает провайдер контекста контейнера задач.
 */
export const TasksContextProvider: FC = ({ children }) => {
  const session = useSession();
  const queryClient = useQueryClient();

  const [tasks, setTasks] = useState<Array<[string, TaskType]>>([]);
  const [isInitializing, setIsInitializing] = useState(false);

  const { data: initialTasksData } = useQuery(
    [TASKS_EMPLOYEE_KEY, session.user?.id],
    () => session.client.fetchEmployeeTasks(session.user!.id),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: session.isAuthenticated,
    },
  );
  const initialTasks = initialTasksData?.items;
  const initialTasksNotFinished =
    initialTasks?.reduce((acc, task) => {
      if (task.status === TaskStatus.Pending) {
        acc.push([task.id, task.type]);
      }

      return acc;
    }, [] as Array<[string, TaskType]>) ?? [];

  const tasksData = useQueries(
    [...tasks, ...initialTasksNotFinished].map(([taskID]) => ({
      enabled: session.isAuthenticated,
      queryKey: [TASKS_KEY, taskID],
      queryFn: () => session.client.fetchBackgroundTask(taskID),
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchInterval: (data?: Task) =>
        data?.status !== TaskStatus.Finished ? POLLING_INTERVAL : false,
      onSuccess(data: Task) {
        if (data.status === TaskStatus.Pending) {
          return;
        }

        // TODO: Temporary fix. Clearing all affected cache.
        // Add affected cache keys to task descriptors.
        queryClient.invalidateQueries(CONTRACT_KEY);
        queryClient.invalidateQueries(CONTRACTS_KEY);
        queryClient.invalidateQueries(BANKRUPTCY_KEY);
        queryClient.invalidateQueries(JURISDICTION_KEY);
        queryClient.invalidateQueries(JURISDICTION_LAST_KEY);
        queryClient.invalidateQueries(DEBT_KEY);
        queryClient.invalidateQueries(ATTACHMENTS_KEY);
        queryClient.invalidateQueries(STATE_DUTY_KEY);
        queryClient.invalidateQueries(STATE_DUTIES_KEY);
        queryClient.invalidateQueries(PRINT_KEY);
        queryClient.invalidateQueries(TASKS_EMPLOYEE_KEY);
      },
    })),
  );

  const value = useMemo(
    () => ({
      tasks: (
        [
          ...(initialTasks?.map((task) => [task.id, task.type]) ?? []),
          ...tasks,
        ] as Array<[string, TaskType]>
      ).reduce((acc, [id, type]) => {
        const result =
          tasksData.find((taskData) => taskData.data?.id === id)?.data ??
          initialTasks?.find((taskData) => taskData.id === id);

        if (result) {
          acc[id] = result;
        } else {
          acc[id] = {
            id,
            status: TaskStatus.Pending,
            type,
            dateStarted: new Date(),
            processedTotal: 0,
            processedSuccess: 0,
            processedError: 0,
          };
        }

        return acc;
      }, {} as Record<string, Task>),

      isInitializing,

      startInitialization: () => {
        setIsInitializing(true);
      },

      finishInitialization: () => {
        setIsInitializing(false);
      },

      track: (taskID: string, taskType: TaskType) => {
        setTasks((oldTasks) => [...oldTasks, [taskID, taskType]]);
      },
    }),
    [tasks, tasksData, isInitializing, initialTasks],
  );

  return (
    <TasksContext.Provider value={value}>{children}</TasksContext.Provider>
  );
};

export default TasksContext;
