import { FC } from 'react';

import { Box, Divider, makeStyles, Badge, Button } from '@material-ui/core';

import { useRouter } from 'next/router';

import Route from 'constants/Route';
import RouteHelper from 'helpers/RouteHelper';
import TaskHelper from 'helpers/TaskHelper';
import TimeHelper from 'helpers/TimeHelper';
import { TaskStatus, TaskType } from 'types/entities/Task';
import useSession from 'hooks/useSession';
import useNotifications from 'hooks/useNotifications';
import NotificationIcon from 'components/ui/NotificationIcon';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * ID фоновой задачи.
   */
  uuid: string;

  /**
   * Тип фоновой задачи.
   */
  taskType?: TaskType;

  /**
   * Время выполнения задачи.
   */
  timeProgress?: Date;

  /**
   * Статус выполнения задачи.
   */
  status: TaskStatus;

  /**
   * Определяет, что оповещение не прочитано.
   */
  unread?: boolean;

  /**
   * Обрабатывает закрытие меню.
   */
  onClose?: () => void;

  /**
   * Флаг, что все договора в фоновой задаче с ошибкой.
   */
  allProcessedError: boolean;
};

/**
 * Возвращает коллекцию классов элементов.
 */
const useStyles = makeStyles((theme) => ({
  item: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1.5)}px`,
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },

  info: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    gap: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
  },

  subtext: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular,
  },

  header: {
    fontWeight: theme.typography.fontWeightMedium,
  },

  button: {
    width: 155,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,

    fontSize: 13,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
  },

  buttonBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 7,

    marginTop: theme.spacing(1),
  },
}));

/**
 * Отображает элемент в блоке оповещений.
 */
const HeaderNotificationItem: FC<Props> = ({
  onClose,
  taskType,
  timeProgress,
  status,
  unread,
  uuid,
  allProcessedError,
  ...props
}) => {
  const session = useSession();
  const styles = useStyles();
  const router = useRouter();
  const notifications = useNotifications();

  /**
   * Отдает результат выполнения фоновой задачи.
   */
  async function getResult() {
    const data = await session.client.fetchBackgroundTask(uuid);

    if (data.result?.url) {
      window.open(data.result?.url, '_blank', 'noreferrer');
    } else {
      let url: string;
      if (taskType === TaskType.StateDutyPaymentApprove) {
        url = RouteHelper.compile(Route.TASKS);
      } else {
        url = RouteHelper.compile(Route.CONTRACTS_TASK, { id: uuid });
      }

      router.push(url);
    }

    notifications.markRead(uuid);

    onClose?.();
  }

  return (
    <>
      <Box {...props} className={styles.item}>
        <Box>
          <NotificationIcon
            status={status}
            allProcessedError={allProcessedError}
          />
        </Box>
        <Box className={styles.info}>
          <Box className={styles.header}>
            {TaskHelper.getTaskTypeText(taskType)}
          </Box>
          <Box
            className={styles.subtext}
          >{`Начато в ${TimeHelper.convertNativeToSystem(timeProgress)}`}</Box>

          {status === TaskStatus.Finished && !allProcessedError && (
            <Box className={styles.buttonBox}>
              <Button
                onClick={getResult}
                variant="outlined"
                color="primary"
                size="small"
                className={styles.button}
              >
                Результат
              </Button>
              <Badge
                color="secondary"
                variant="dot"
                invisible={!unread}
                overlap="rectangular"
              />
            </Box>
          )}
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default HeaderNotificationItem;
