/**
 * Параметр, по которому происходит поиск списка договоров.
 */
enum ContractsSearchType {
  /**
   * Поиск договоров по их номеру.
   */
  BY_NUMBER = 'by_number',

  /**
   * По ФИО заёмщика.
   */
  BY_DEBTOR_NAME = 'by_debtor_name',

  /**
   * По телефону заёмщика.
   */
  // TODO поиск по номеру телефона временно скрыт,
  //  вернуть, когда станет актуально
  // BY_DEBTOR_PHONE = 'by_debtor_phone',

  /**
   * По юр. лицу, выдавшему заём.
   */
  // TODO: временно скрыт
  //   BY_COMPANY = 'by_company',
}

export default ContractsSearchType;
