import { format } from 'date-fns';

/**
 * Содержит низкоуровневые методы для работы с временем.
 */
export default class TimeHelper {
  /**
   * Преобразует данный объект даты в строку в формате
   * hh:mm.
   * @param value Объект даты.
   */
  public static convertNativeToSystem(value?: Date) {
    if (!value) {
      return '—';
    }

    return format(value, 'HH:mm');
  }
}
