import React, {
  ComponentPropsWithoutRef,
  FC,
  useState,
  MouseEvent,
} from 'react';

import {
  ClickAwayListener,
  Card,
  Popper,
  Grow,
  makeStyles,
  Divider,
  CardActions,
  CardHeader,
  Badge,
  IconButton,
} from '@material-ui/core';
import { Notifications, NotificationsNoneOutlined } from '@material-ui/icons';
import classNames from 'classnames';

import Route from 'constants/Route';
import useNotifications from 'hooks/useNotifications';
import Button from 'components/ui/Button';

import HeaderNotificationEmpty from './HeaderNotificationEmpty';
import HeaderNotificationItem from './HeaderNotificationItem';
import useTasks from 'hooks/useTasks';

/**
 * Свойства компонента.
 */

type Props = Omit<ComponentPropsWithoutRef<typeof Popper>, 'children' | 'open'>;

/**
 * Количество задач в предварительном просмотре.
 */
const TASKS_BRIEF_LIMIT = 3;

/**
 * Возвращает коллекцию классов элементов.
 */
const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: theme.zIndex.appBar,
  },

  card: {
    marginTop: theme.spacing(1),
    width: 300,
  },

  headerTitleRoot: {
    padding: 12,
  },

  title: {
    fontSize: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  },

  menu: {},

  buttonRoot: {
    width: '40px',
    height: '40px',
    color: '#fff',
    boxShadow: 'none',

    '&:hover': {
      backgroundColor: '#515EC5',
    },
  },

  active: {
    backgroundColor: '#515EC5',
  },

  transparent: {
    backgroundColor: 'transparent',
  },

  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

/**
 * Отображает блок оповещений в шапке.
 */
const HeaderNotification: FC<Props> = ({ className, ...props }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { tasks } = useTasks();
  const notifications = useNotifications();

  const visibleTasks = Object.values(tasks).slice(0, TASKS_BRIEF_LIMIT);
  const hasUnread =
    visibleTasks.some((task) => !notifications.isRead(task.id)) ?? false;

  const styles = useStyles();

  function handleClick(event: MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const tasksList = Object.values(tasks);

  return (
    <>
      <IconButton
        aria-label="notifications"
        onClick={handleClick}
        className={classNames(styles.buttonRoot, className, {
          [styles.active]: !!anchorEl,
          [styles.transparent]: !anchorEl,
        })}
      >
        <Badge color="secondary" variant="dot" invisible={!hasUnread}>
          {hasUnread ? <Notifications /> : <NotificationsNoneOutlined />}
        </Badge>
      </IconButton>

      <Popper
        {...props}
        id="headerProfilePopper"
        className={styles.popper}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
              <Card classes={{ root: styles.card }} elevation={5}>
                <CardHeader
                  title="Мои действия"
                  classes={{
                    root: styles.headerTitleRoot,
                    title: styles.title,
                  }}
                />
                <Divider />
                {tasksList.slice(0, TASKS_BRIEF_LIMIT).map((task) => (
                  <HeaderNotificationItem
                    key={task.id}
                    uuid={task.id}
                    onClose={handleClose}
                    taskType={task.type}
                    timeProgress={task.dateStarted}
                    status={task.status}
                    allProcessedError={
                      task.processedTotal === task.processedError
                    }
                    unread={!notifications.isRead(task.id)}
                  />
                ))}
                {Boolean(tasksList.length) && (
                  <CardActions>
                    <Button
                      className={styles.link}
                      color="primary"
                      fullWidth
                      href={Route.TASKS}
                      onClick={handleClose}
                    >
                      Показать все
                    </Button>
                  </CardActions>
                )}
                {!tasksList.length && <HeaderNotificationEmpty />}
              </Card>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default HeaderNotification;
