import { ComponentPropsWithoutRef, FC } from 'react';
import MuiAvatar from '@material-ui/core/Avatar';

/**
 * Возвращает цвет, ассоциируемый с текстовыми данными.
 * @param string Текстовые данные.
 */
function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
}

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof MuiAvatar>, 'alt'> & {
  /**
   * Имя пользователя.
   */
  firstName?: string;

  /**
   * Фамилия пользователя.
   */
  lastName?: string;
};

/**
 * Отображает аватар пользователя
 */
const Avatar: FC<Props> = ({ firstName, lastName, ...props }) => {
  return (
    <MuiAvatar
      {...props}
      alt={[firstName, lastName].join(' ').toLocaleUpperCase()}
      style={{
        color: '#fff',
        backgroundColor: stringToColor(`${firstName}_${lastName}`),
      }}
    >
      {`${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`}
    </MuiAvatar>
  );
};

export default Avatar;
