import React, { ComponentPropsWithoutRef, FC, MouseEvent } from 'react';
import { makeStyles } from '@material-ui/core';

import Avatar from 'components/ui/Avatar';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Avatar>, 'onClick'> & {
  /**
   * Обработчик события нажатия на кнопку.
   * @param event Событие нажатия.
   */
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
};

/**
 * Возвращает коллекцию классов элементов.
 */
const useStyle = makeStyles(() => ({
  buttonRoot: {
    width: 40,
    height: 40,
    minWidth: 40,
    minHeight: 40,
    borderRadius: '100%',
    border: 0,
    background: 0,
    padding: 0,
    margin: 0,
    marginLeft: 16,
    cursor: 'pointer',
  },

  avatar: {
    width: '100%',
    height: '100%',
  },
}));

/**
 * Отображает кнопку для вызова выпадающего меню пользователя в шапке.
 */
const HeaderProfileButton: FC<Props> = ({ onClick, ...props }) => {
  const style = useStyle();

  return (
    <button type="button" className={style.buttonRoot} onClick={onClick}>
      <Avatar {...props} className={style.avatar} />
    </button>
  );
};

export default HeaderProfileButton;
