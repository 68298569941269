import React, { FC } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle, Cancel } from '@material-ui/icons';

import { TaskStatus } from 'types/entities/Task';
import { CircularProgress } from '@material-ui/core';

/**
 * Возвращает классы элементов.
 */
const useStyles = makeStyles((theme) => ({
  success: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: theme.palette.success.main,
  },

  error: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    color: theme.palette.error.main,
  },

  circle: {
    borderRadius: 8,
    backgroundColor: theme.palette.info.lightBackground,
  },
}));

type Props = {
  /**
   * Флаг, что все договора в фоновой задаче с ошибкой.
   */
  allProcessedError: boolean;

  /**
   * Статус фоновой задачи.
   */
  status: TaskStatus;
};

const NotificationIcon: FC<Props> = ({ allProcessedError, status }) => {
  const styles = useStyles();

  if (status === TaskStatus.Pending) {
    return <CircularProgress size={16} className={styles.circle} />;
  }

  return allProcessedError ? (
    <Cancel className={styles.error} />
  ) : (
    <CheckCircle className={styles.success} />
  );
};

export default NotificationIcon;
