import {
  ComponentPropsWithoutRef,
  FC,
  MouseEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  Box,
  Button,
  ClickAwayListener,
  Fade,
  IconButton,
  InputBase,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
} from '@material-ui/core';
import { ArrowDropDown, Close, Search } from '@material-ui/icons';
import cn from 'classnames';
import color from 'color';
import { useRouter } from 'next/router';
import * as Yup from 'yup';

import ContractsSearchType from 'constants/ContractsSearchType';
import Route from 'constants/Route';
import ContractsHelper from 'helpers/ContractsHelper';
import useForm from 'hooks/useForm';

/**
 * Поля формы.
 */
interface Fields {
  /**
   * Поисковый запрос.
   */
  text: string;

  /**
   * Параметр, по которому ведётся поиск.
   */
  type: string;
}

/**
 * Пользовательские свойства компонента.
 */
type CustomProps = {
  /**
   * Обрабатывает изменение состояния активности поля поиска.
   * @param active `True`, если поле стало активно, в противном случае `false`.
   */
  onActiveChange?: (active: boolean) => void;

  /**
   * Указывает, что поле поиска должно быть активно.
   */
  active?: boolean;

  /**
   * Тип, по которому ведётся поиск.
   */
  searchType?: string;

  /**
   * Текст, по которому ведется поиск.
   */
  searchText?: string;
};

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof Box> & CustomProps;

/**
 * Возвращает классы элементов.
 */
const useStyles = makeStyles<Theme, CustomProps>((theme) => ({
  root: {
    backgroundColor: color(theme.palette.common.white).fade(0.9).string(),
    borderRadius: theme.shape.borderRadius,

    transition: theme.transitions.create('color'),

    display: 'flex',

    color(p) {
      const baseColor = theme.palette.primary.contrastText;
      return p.active ? baseColor : color(baseColor).fade(0.4).string();
    },
  },

  search: {
    transition: theme.transitions.create('color'),

    paddingRight: 19,
    paddingLeft: 19,

    paddingBottom: (36 - 20) / 2,
    paddingTop: (36 - 20) / 2,

    borderBottomLeftRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,

    flex: '0 0 auto',
  },

  close: {
    transition: theme.transitions.create('color'),

    paddingBottom: (36 - 20) / 2,
    paddingTop: (36 - 20) / 2,

    borderRadius: 0,

    flex: '0 0 auto',
  },

  input: {
    paddingBottom: 0,
    paddingTop: 0,

    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.primary.contrastText,

    flex: '1 1 auto',
  },

  buttons: {
    display: 'flex',
  },

  type: {
    transition: theme.transitions.create('color'),

    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,

    whiteSpace: 'nowrap',

    flex: '0 0 auto',
  },
}));

/**
 * Отображает форму поиска.
 */
const HeaderSearchForm: FC<Props> = ({
  className,
  onActiveChange,
  active,
  searchType,
  searchText,
  ...props
}) => {
  const router = useRouter();
  const styles = useStyles({ active });

  const [menuNode, setMenuNode] = useState<any>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const focused = useRef<boolean>(false);
  const timeout = useRef<any>();

  function handleTimeout() {
    focused.current = false;
    onActiveChange?.(false);
  }

  const schema = useMemo(
    (): Yup.SchemaOf<Fields> =>
      Yup.object().shape({
        text: Yup.string().default(''),
        type: Yup.string().default(ContractsSearchType.BY_NUMBER),
      }),
    [],
  );

  const form = useForm<Fields>({
    name: 'contractsSearch',

    validationSchema: schema,

    onSubmit(values) {
      form.setFieldValue('text', form.valueOf('text').trim());

      const type = values.type as ContractsSearchType;
      const { text } = values;

      // TODO поиск по номеру телефона временно скрыт,
      //  вернуть, когда станет актуально

      // if (type === ContractsSearchType.BY_DEBTOR_PHONE) {
      //   text = ContractsHelper.removeAllNonNumbersFromValue(text);
      // }

      // TODO: Refactoring
      /* if (!store.router.memoryHref) {
       *   store.router.setMemoryHref(router.asPath);
       * } */

      const href = ContractsHelper.getSearchHref(type, text.trim());
      router.push(href);

      clearTimeout(timeout.current);
      handleTimeout();

      if (inputRef.current && document.activeElement === inputRef.current) {
        inputRef.current.blur();
      }
    },
  });

  const formRef = useRef(form);

  formRef.current = form;

  function handleFocus() {
    clearTimeout(timeout.current);

    if (focused.current) {
      return;
    }

    focused.current = true;
    onActiveChange?.(true);
  }

  function handleBlur() {
    clearTimeout(timeout.current);
    form.setFieldValue('text', form.valueOf('text').trim());

    if (form.valueOf('text') || !focused.current) {
      return;
    }

    timeout.current = setTimeout(handleTimeout, 125);
  }

  function handleMenuClick(event: MouseEvent<HTMLElement>) {
    setMenuNode(event.currentTarget);
  }

  function handleSearch() {
    if (form.valueOf('text')) {
      form.submitForm();
      return;
    }

    inputRef.current?.focus();
  }

  function handleClose() {
    clearTimeout(timeout.current);
    form.resetForm();
    handleTimeout();

    // TODO: Refactoring
    /* if (store.router.memoryHref) {
*   router.back();

*   store.router.resetMemoryHref();
* } else {
*   router.push(Route.CONTRACTS_PORTFOLIO);
* } */
    router.push(Route.CONTRACTS_PORTFOLIO);
  }

  const menuItems = Object.values(ContractsSearchType).map(
    (type: ContractsSearchType) => {
      function handleMenuItemClick() {
        form.handleChangeOf('type')(type);
        setMenuNode(null);
      }

      return (
        <MenuItem key={type} onClick={handleMenuItemClick}>
          {ContractsHelper.renderSearchType(type)}
        </MenuItem>
      );
    },
  );

  useEffect(() => {
    if (searchType && searchText) {
      formRef.current.handleChangeOf('type')(searchType);
      formRef.current.handleChangeOf('text')(searchText);
      onActiveChange?.(true);
    }
  }, [searchType, searchText, onActiveChange]);

  return (
    <form
      {...form.bindForm()}
      {...props}
      className={cn(styles.root, className)}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      <IconButton
        className={styles.search}
        onClick={handleSearch}
        color="inherit"
      >
        <Search fontSize="small" />
      </IconButton>
      <InputBase
        {...form.bindInput('text')}
        className={styles.input}
        inputRef={inputRef}
        fullWidth
        placeholder="Поиск"
      />
      <Fade
        in={active && Boolean(form.valueOf('text'))}
        mountOnEnter
        unmountOnExit
      >
        <IconButton
          className={styles.close}
          color="inherit"
          onClick={handleClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </Fade>

      <Fade in={active} mountOnEnter unmountOnExit>
        <>
          <ClickAwayListener onClickAway={() => setMenuNode(null)}>
            <Button
              className={styles.type}
              color="inherit"
              endIcon={<ArrowDropDown />}
              onClick={handleMenuClick}
            >
              {ContractsHelper.renderSearchType(
                form.valueOf('type') as ContractsSearchType,
              )}
            </Button>
          </ClickAwayListener>
        </>
      </Fade>

      <Menu
        id="contractsSearchMenu"
        open={Boolean(menuNode)}
        anchorEl={menuNode}
      >
        {menuItems}
      </Menu>
    </form>
  );
};

export default HeaderSearchForm;
