import { FC, ComponentPropsWithoutRef } from 'react';

import { MenuList, makeStyles, Box } from '@material-ui/core';
import cn from 'classnames';

import Route from 'constants/Route';
import useSession from 'hooks/useSession';

import HeaderMenuItem from './HeaderMenuItem';

/**
 * Свойства компонента.
 */
type Props = ComponentPropsWithoutRef<typeof MenuList>;

/**
 * Возвращает классы компонента.
 */
const useStyle = makeStyles(() => ({
  root: {
    display: 'flex',

    paddingBottom: 0,
    paddingTop: 0,
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

/**
 * Отображает основное меню в шапке сайта.
 */
const HeaderMenu: FC<Props> = ({ className, ...props }) => {
  const style = useStyle();

  const session = useSession();

  return (
    <MenuList
      {...props}
      className={cn(style.root, className)}
      // @ts-ignore
      component="nav"
    >
      <HeaderMenuItem
        href={Route.CONTRACTS_ALL}
        key={Route.CONTRACTS_ALL}
        data-qa="navbar-main-allloans"
      >
        Все договоры
      </HeaderMenuItem>
      <HeaderMenuItem
        href={Route.CONTRACTS_PORTFOLIO}
        key={Route.CONTRACTS_PORTFOLIO}
        data-qa="navbar-main-portfolio"
      >
        Портфель
      </HeaderMenuItem>
      <HeaderMenuItem
        href={Route.CONTRACTS_HISTORY}
        key={Route.CONTRACTS_HISTORY}
        data-qa="navbar-main-audit"
      >
        Журнал аудита
      </HeaderMenuItem>
      {session.isAdmin && (
        <Box className={style.box}>
          <HeaderMenuItem
            href={Route.GOSFEE}
            key={Route.GOSFEE}
            data-qa="navbar-main-feepayment"
          >
            Оплаты ГП
          </HeaderMenuItem>
          {/* <HeaderMenuItem
            href={Route.JUDICIAL_SECTOR}
            key={Route.JUDICIAL_SECTOR}
          >
            Справочник СУ
          </HeaderMenuItem> */}
          <HeaderMenuItem
            href={Route.REGISTER_UZ}
            key={Route.REGISTER_UZ}
            data-qa="navbar-main-registry"
          >
            Реестр для УЗ
          </HeaderMenuItem>
          <HeaderMenuItem href={Route.COURTS} key={Route.COURTS}>
            Справочник СУ
          </HeaderMenuItem>
        </Box>
      )}
    </MenuList>
  );
};

export default HeaderMenu;
