import { FC, useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';

/**
 * Свойства компонента.
 */
type Props = {
  /**
   * Идентификатор корневого элемента портала.
   */
  containerID: string;
};

/**
 * Создаёт портал в указанной позиции DOM.
 */
const Portal: FC<Props> = ({ containerID, children }) => {
  const ref = useRef<HTMLElement>();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.getElementById(containerID) ?? undefined;
    setMounted(true);

    return () => setMounted(false);
  }, [containerID]);

  return mounted ? createPortal(children, ref.current!) : null;
};

export default Portal;
