/**
 * Представляет ФИО человека.
 */
type Name = {
  /**
   * Отчество.
   */
  second?: string;

  /**
   * Имя.
   */
  first: string;

  /**
   * Фамилия.
   */
  last: string;
};

/**
 * Содержит низкоуровневые методы для работы с именами.
 */
export default class NameHelper {
  /**
   * Собирает объект, который представляет ФИО человека.
   * @param last Фамилия.
   * @param first Имя.
   * @param second Отчество.
   */
  public static build(last: string, first: string, second?: string) {
    const name: Name = {
      second,
      first,
      last,
    };

    return name;
  }

  /**
   * Преобразует указанные компоненты имени в строку с полным ФИО.
   * @param last Фамилия.
   * @param first Имя.
   * @param second Отчество.
   */
  public static renderFull(
    last: string,
    first: string,
    second?: string,
  ): string;

  /**
   * Преобразует указанный объект в строку с полным ФИО.
   * @param name Объект, представляющий имя.
   */
  public static renderFull(name: Name): string;

  /**
   * Преобразует указанное имя в полное ФИО.
   * @param last Фамилия или объект, представляющий ФИО.
   * @param first Имя.
   * @param second Отчество.
   */
  public static renderFull(
    last: Name | string,
    first?: string,
    second?: string,
  ) {
    const name =
      typeof last === 'string'
        ? this.build(last, first as string, second)
        : last;

    return [name.last, name.first, name.second].filter(Boolean).join(' ');
  }

  /**
   * Возвращает инициал для указанного компонента имени.
   * @param value Компонент имени.
   * @param withDot Признак наличия точки.
   */
  private static getInitial(value?: string, withDot = true) {
    if (!value) {
      return undefined;
    }

    const firstLetter = value.substr(0, 1);
    const dot = withDot ? '.' : '';
    return `${firstLetter}${dot}`;
  }

  /**
   * Преобразует указанные компоненты имени в строку ФИО с инициалами.
   * @param last Фамилия.
   * @param first Имя.
   * @param second Отчество.
   */
  public static renderInitials(
    last: string,
    first: string,
    second?: string,
  ): string;

  /**
   * Преобразует указанный объект в строку строку ФИО с инициалами.
   * @param name Объект, представляющий имя.
   */
  public static renderInitials(name: Name): string;

  /**
   * Преобразует указанное имя в ФИО с инициалами.
   * @param last Фамилия или объект, представляющий ФИО.
   * @param first Имя.
   * @param second Отчество.
   */
  public static renderInitials(
    last: Name | string,
    first?: string,
    second?: string,
  ) {
    const name =
      typeof last === 'string'
        ? this.build(last, first as string, second)
        : last;

    return [
      name.last,
      this.getInitial(name.first),
      this.getInitial(name.second),
    ]
      .filter(Boolean)
      .join(' ');
  }

  /**
   * Преобразует указанное имя в строку ФИО, в которой все инициалы.
   * @param last Фамилия.
   * @param first Имя.
   * @param second Отчество.
   */
  public static renderInitialsOnly(
    last: string,
    first: string,
    second?: string,
  ) {
    const name = this.build(last, first, second);

    return [
      this.getInitial(name.last, false),
      this.getInitial(name.first, false),
      this.getInitial(name.second, false),
    ]
      .filter(Boolean)
      .join('');
  }

  /**
   * Преобразует указанное имя в строку вида "Имя Фамилия".
   * @param name Объект, представляющий ФИО.
   */
  public static renderName(name: Name): string;

  /**
   * Преобразует указанное имя в строку вида "Имя Фамилия".
   * @param last Фамилия или объект, представляющий ФИО.
   * @param first Имя.
   */
  public static renderName(last: string, first: string): string;

  /**
   * Преобразует указанное имя в строку вида "Имя Фамилия".
   * @param last Фамилия или объект, представляющий ФИО.
   * @param first Имя.
   */
  public static renderName(last: Name | string, first?: string) {
    const name =
      typeof last === 'string' ? this.build(last, first as string) : last;

    return [name.first, name.last].filter(Boolean).join(' ');
  }
}
