import ContractsSearchType from 'constants/ContractsSearchType';
import Route from 'constants/Route';
import { FilterCriteria } from 'types/FilterCriteria';

import RouteHelper from './RouteHelper';

/**
 * Названия параметров поиска в адресе страницы.
 */
enum QueryKey {
  /**
   * Тип поиска в адресной строке.
   */
  TYPE = 'type',

  /**
   * Текст поиска в адресной строке.
   */
  TEXT = 'text',
}

/**
 * Предоставляет методы для работы со списком договоров.
 */
export default class ContractsHelper {
  /**
   * Возвращает строковое представление параметра, по которому ведётся поиск.
   * @param type Параметр, по которому ведётся поиск.
   */
  public static renderSearchType(type: ContractsSearchType) {
    switch (type) {
      // TODO поиск по номеру телефона временно скрыт,
      //  вернуть, когда станет актуально
      // case ContractsSearchType.BY_DEBTOR_PHONE: {
      //   return 'Телефон заемщика';
      // }

      case ContractsSearchType.BY_DEBTOR_NAME: {
        return 'ФИО заемщика';
      }

      // TODO: временно скрыт
      //   case ContractsSearchType.BY_COMPANY: {
      //     return 'Юр. лицо выдачи займа';
      //   }

      case ContractsSearchType.BY_NUMBER: {
        return '№ договора';
      }

      default: {
        throw new Error(
          `Expect 'type' to be a ContractsSearchType; got ${type}`,
        );
      }
    }
  }

  /**
   * Возвращает строковое представление параметра, по которому ведётся поиск, в
   * дательном падеже.
   * @param type Параметр, по которому ведётся поиск.
   */
  public static renderSearchTypeDative(type: ContractsSearchType) {
    switch (type) {
      // TODO поиск по номеру телефона временно скрыт,
      //  вернуть, когда станет актуально
      // case ContractsSearchType.BY_DEBTOR_PHONE: {
      //   return 'телефону заемщика';
      // }

      case ContractsSearchType.BY_DEBTOR_NAME: {
        return 'ФИО заемщика';
      }

      // TODO: временно скрыт
      //   case ContractsSearchType.BY_COMPANY: {
      //     return 'юр. лицу выдачи займа';
      //   }

      case ContractsSearchType.BY_NUMBER: {
        return '№ договора';
      }

      default: {
        throw new Error(
          `Expect 'type' to be a ContractsSearchType; got ${type}`,
        );
      }
    }
  }

  /**
   * Возвращает ссылку на страницу результатов поиска по указанным криетриям.
   * @param type Параметр, по которому ведётся поиск.
   * @param text Поисковый запрос.
   */
  public static getSearchHref(type: ContractsSearchType, text: string) {
    return RouteHelper.addQueryToHref(Route.CONTRACTS_SEARCH, {
      type,
      text,
    });
  }

  /**
   * Возвращает тип поиска, найденный в адресной строке.
   * @param href Полный адрес страницы.
   */
  public static getSearchTypeFromHref(href: string) {
    const query = RouteHelper.getHrefQuery(href);

    const value = query[QueryKey.TYPE];

    if (!value) {
      return undefined;
    }

    return Object.values(ContractsSearchType).includes(
      value as ContractsSearchType,
    )
      ? (value as ContractsSearchType)
      : ContractsSearchType.BY_NUMBER;
  }

  /**
   * Возвращает текст, найденный в адресной строке.
   * @param href Полный адрес страницы.
   */
  public static getSearchTextFromHref(href: string) {
    const query = RouteHelper.getHrefQuery(href);

    const value = query[QueryKey.TEXT];

    if (!value) {
      return undefined;
    }

    return value;
  }

  /**
   * Возвращает параметры поиска, найденные в адресной строке.
   * @param href Полный адрес страницы.
   */
  public static getSearchParamsFromHref(href: string): FilterCriteria {
    const searchType = this.getSearchTypeFromHref(href);
    const searchText = this.getSearchTextFromHref(href);

    if (undefined === searchType || undefined === searchText) {
      return {};
    }

    switch (this.getSearchTypeFromHref(href)) {
      case ContractsSearchType.BY_NUMBER:
        return {
          contractNumberStarts: searchText,
        };

      // TODO: временно скрыт
      //   case ContractsSearchType.BY_COMPANY:
      //     return {
      //       creditCompanyNameStarts: searchText,
      //     };

      case ContractsSearchType.BY_DEBTOR_NAME:
        return {
          debtorNameStarts: searchText,
        };

      default:
        return {};
    }
  }
}
