import { ComponentPropsWithoutRef, FC, useState, MouseEvent } from 'react';

import {
  ClickAwayListener,
  Card,
  Popper,
  Grow,
  makeStyles,
  Divider,
  CardActions,
  CardHeader,
  Button,
} from '@material-ui/core';

import NameHelper from 'helpers/NameHelper';
import useSession from 'hooks/useSession';
import { UserRole } from 'types/entities/User';

import HeaderProfileButton from './HeaderProfileButton';
import HeaderProfileMenu from './HeaderProfileMenu';

/**
 * Свойства компонента.
 */
type Props = Omit<ComponentPropsWithoutRef<typeof Popper>, 'children' | 'open'>;

/**
 * Возвращает коллекцию классов элементов.
 */
const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: theme.zIndex.appBar,
  },

  card: {
    marginTop: theme.spacing(1),
    width: 280,
  },

  title: {
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightMedium,
  },

  subheader: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
  },

  menu: {},
}));

/**
 * Возвращает текст, соответствующий роли пользователя.
 * @param role Роль.
 */
function getRoleText(role?: UserRole) {
  switch (role) {
    case UserRole.Admin: {
      return 'Администратор';
    }

    case UserRole.Owner: {
      return 'Владелец';
    }

    case UserRole.User: {
      return 'Сотрудник';
    }

    default: {
      return '';
    }
  }
}

/**
 * Отображает блок профиля пользователя в шапке.
 */
const HeaderProfile: FC<Props> = ({ className, ...props }) => {
  const session = useSession();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const classes = useStyles();

  const role = getRoleText(session.user?.role);

  const name = session.user
    ? NameHelper.renderName(session.user.lastName, session.user.firstName)
    : '';

  function handleClick(event: MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <HeaderProfileButton
        className={className}
        firstName={session.user?.firstName}
        lastName={session.user?.lastName}
        src={session.user?.avatar}
        onClick={handleClick}
      />

      <Popper
        {...props}
        id="headerProfilePopper"
        className={classes.popper}
        anchorEl={anchorEl}
        open={open}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
              <Card classes={{ root: classes.card }} elevation={5}>
                <CardHeader
                  subheader={role}
                  title={name}
                  classes={{
                    subheader: classes.subheader,
                    title: classes.title,
                  }}
                />
                <Divider />
                <HeaderProfileMenu onClick={handleClose} />
                <CardActions>
                  <Button
                    onClick={session.logout}
                    variant="outlined"
                    color="primary"
                    fullWidth
                  >
                    Выйти
                  </Button>
                </CardActions>
              </Card>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default HeaderProfile;
